.dropzoneWrapper {
  position: relative;
  min-height: 100px;
  margin: 10px 0 35px;
  border: 1px dashed #262a33;
}

.dropzone {
  position: relative;
  display: flex;
  min-height: 100px;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
}

.label {
  color: #262a33;
  font-weight: 500;
}

.dropzoneInput {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  cursor: pointer;
  opacity: 0;
}

.linkButton {
  color: #0d7680;
  font-weight: 500;
  border-bottom: 1px solid #0d7680;
}

.previewImageContainer {
  position: relative;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  margin: 0 0 5px 5px;
  height: 100px;

  .previewImageWrapper {
    padding-right: 5px;
  }

  .previewImage {
    width: 100px;
    height: 100px;
    margin-right: 5px;
    object-fit: cover;
  }
}

.invalidNameMessage{
  color: #ff0000;
  margin-bottom: 30px;
}

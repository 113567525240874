.wrapper {
  text-align: center;
  width: 45%;
  margin: 0 auto;
}

.actions {
  padding-top: 40px;
}

.button {
  padding: 10px;
}

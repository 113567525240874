.clearCacheButton {
  padding: 5px 10px;
  color: #ffffff !important;
  background-color: transparent;
  font-size: 14px;
  cursor: pointer;
  font-weight: 400;

  button,
  span {
    color: #ffffff !important;
  }
}

@media screen and (max-width: 740px) {
  .clearCacheButton {
    padding: 10px 20px;
    border: 0;
    color: #000000;

    button,
    span {
      color: #0d7680 !important;
    }
  }
}

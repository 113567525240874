.tabLinkWrapper {
  padding: 0 !important;

  .tabLink {
    display: inline-block;
    padding: 6px 8px;
  }

  .bigTab {
    padding: 11px 20px;
  }
}

.sideBar {
  display: inline-block;
  width: 260px;
  overflow-y: hidden !important;
}

.mainContent {
  display: inline-block;
  width: calc(100% - 260px);
  vertical-align: top;
}

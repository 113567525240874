.actionsCell button {
  margin: 2px;
}

.checkboxCell {
  max-width: 40px !important;
  width: 40px !important;
  min-width: 40px !important;
  padding: 10px !important;

  label,
  div {
    margin: 0 !important;
  }
}

.tickBox::after {
  content: url('../../../assets/icons/icon-checked.svg');
  width: 15px;
  height: 11px;
  padding: 1px;
  text-align: center;
  color: #0d7680;
}

.timelineWrapper {
  position: relative;
  margin: 40px auto 20px;
}

.buttonWrapper {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 999;

  .actionButtons {
    text-align: right;
  }
}

.timeline {
  margin: 0 auto;
  text-align: left;

  // Fails to target "o-grid-row"
  & > div > div {
    min-height: 66px;
  }
}

.shortenedAlumniLetter {
  max-height: 100px;
  overflow: hidden;
}

@media screen and (max-width: 740px) {
  .buttonWrapper {
    position: relative;
    margin-bottom: 30px;
  }
}

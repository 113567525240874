.editableWidget {
  width: 100%;

  .widgetContent {
    border: 2px solid #000000;
    padding: 5px;
    min-height: 200px;
    box-sizing: border-box;
    overflow: auto;
  }
}

.widgetButtonsWrapper {
  display: inline-flex;
  align-items: center;
}

.widgetButton {
  background-color: transparent;
  border: 0;
  display: flex;
  justify-content: space-between;
  cursor: pointer;
  margin: 10px 10px 0;
  align-items: center;

  &.danger {
    color: #990f3d;
  }

  &.primary {
    color: #00757f;
  }

  span {
    margin-left: 7px;
  }
}

.widgetInputWrapper {
  display: flex;
  align-items: baseline;
}

.widgetInput {
  min-width: 80% !important;
  max-width: 80% !important;
}

.widgetInputTextarea {
  height: 150px;
}

.widgetType {
  font-size: 16px !important;
  line-height: 40px !important;
  color: #262a33 !important;
  font-weight: 300 !important;
  margin-bottom: 0 !important;
  text-transform: uppercase;
}

.widgetWrapper {
  margin-bottom: 14px !important;
  display: flex;
  align-items: center;

  * {
    margin: 0 !important;
  }
}

.widgetTitle {
  font-size: 24px !important;
  line-height: 20px !important;
  font-weight: 400 !important;
}

.inputElement {
  width: 100%;
  min-height: 40px;
  padding: 8px;
  background-color: #ffffff;
  border: 1px solid #807973;
  box-sizing: border-box;

  &.error:not(:disabled) {
    color: #cc0000;
    border-color: #cc0000;
  }
}

.faqListContainer {
  font-family: MetricWeb, sans-serif !important;
}

.faqListHeading {
  margin: 2rem 0 2rem 220px !important;
  line-height: 1 !important;
  font-weight: bold !important;
  font-size: 34px !important;
}

.faqList {
  display: flex;
  flex-direction: column;
  margin-left: 225px;
  width: 65%;
}

.faqHeadingContainer {
  display: flex;
  justify-content: space-between;
}

.faqTitle {
  font-size: 21px !important;
  line-height: 24px !important;
  color: #0d7680 !important;
}

.editFaqContainer {
  width: 90%;
  display: flex;
  align-items: flex-end;
}

.deleteFaqButton {
  position: relative;
  left: 90px;
  bottom: 70px;
}

.formActionsContainer {
  position: relative;
  display: flex;
  justify-content: flex-end;
  bottom: 400px;
  left: 250px;

  > div:not(:last-child) {
    margin-right: 10px;
  }
}

.deleteAllWrapper {
  text-align: center;

  @media screen and (max-width: 1104px) {
    text-align: right;
    margin-top: 5px;
    margin-right: 10px;
  }
}
.mobileActions {
  text-align: right;
}

.mba-full-width {
  width: 100%;
}

.mba-center--vertically {
  display: inline-flex;
  align-items: center;
}

.mba-space-between {
  display: flex;
  justify-content: space-between;
}

.mba-flex {
  display: flex;
}

.mba-item-center {
  align-items: center;
}

.mba-no-margin {
  margin: 0 !important;
}

.mba-mt-5 {
  margin-top: 5px !important;
}

.mba-mt-10 {
  margin-top: 10px !important;
}

.mba-mb-5 {
  margin-bottom: 5px !important;
}

.mba-mb-10 {
  margin-bottom: 10px !important;
}

.mba-mr-10 {
  margin-right: 10px !important;
}

.mba-mr-20 {
  margin-right: 20px !important;
}

.mba-ml-5 {
  margin-left: 5px !important;
}

.mba-mt-20 {
  margin-top: 20px !important;
}

.mba-mb-20 {
  margin-bottom: 20px !important;
}

.mba-no-padding {
  padding: 0 !important;
}

.mba-text--center {
  text-align: center;
}

.mba-text--right {
  text-align: right;
}

.mba-text--semi-bold {
  font-weight: 500 !important;
}

.mba-text--accent {
  color: #0d7680 !important;
}

.mba-text--b2b {
  color: #0f5499 !important;
}

.mba-text--error {
  color: #cc0000 !important;
}

.mba-text--danger {
  color: #990f3d !important;
}

.mba-font--14 {
  font-size: 14px !important;
}

.mba-font--16 {
  font-size: 16px !important;
}

.mba-button--non-styled {
  padding: 0;
  background: none;
  border: 0;
  outline: none;
  cursor: pointer;
}

.mba-timeline-button--link {
  padding: 0;
  color: #0d7680;
  font-family: MetricWeb, sans-serif;
  font-size: 18px;
  background: none;
  border: 0;
  cursor: pointer;
  outline: none;
}

.mba-button--link {
  padding: 0;
  color: #0d7680;
  font-family: MetricWeb, sans-serif;
  font-size: 16px;
  text-decoration-color: #cfe4e6;
  text-decoration-thickness: 0.25ex;
  text-decoration-line: underline;
  background: none;
  border: 0;
  cursor: pointer;
  outline: none;

  &:hover {
    color: #08474d;
    text-decoration-color: #9ec8cc;
  }

  &-b2b {
    color: #0f5499;
  }

  &-b2b:hover {
    color: darken(#0f5499, 10%);
    text-decoration-color: lighten(#0f5499, 30%);
  }
}

.mba-z-index-1 {
  z-index: 1 !important;
}

.message {
  width: 300px;
  height: 70px;
  animation-duration: 1.25s;
  animation-name: slidein;
}

@keyframes slidein {
  from {
    transform: translateX(100%);
  }

  to {
    transform: translateX(0%);
  }
}

.main {
  margin-top: -5px !important;
  padding-left: 20px;
  font-size: 16px;
}

.closeButton {
  background-image: url(https://www.ft.com/__origami/service/image/v2/images/raw/fticon-v1%3Across?source=mba-rankings&tint=%2300572c) !important;
  cursor: pointer;
}

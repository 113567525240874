.progressLabel {
  margin: 0;
  font-size: 16px;
  font-weight: 500;
}

.progressBar {
  position: relative;
  display: inline-block;
  width: 85px;
  margin-right: 5px;
  height: 14px;
  background: #c3c3c3;
  vertical-align: middle;
  overflow: hidden;
}

.progressText {
  display: inline-block;
  vertical-align: middle;
  color: #9e2f50;
  font-size: 16px;
  font-weight: 500;
}

.progressBox {
  position: relative;
  display: inline-block;
  width: 8px;
  height: 100%;
  margin-right: 3px;
  background: transparent;
  vertical-align: top;

  &::after {
    content: '';
    position: absolute;
    top: 0;
    right: -3px;
    width: 3px;
    background: #ffffff;
    height: 100%;
  }
}

.progressBarFilled {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  background: #9e2f50;
}

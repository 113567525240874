.schoolWidgetDetails {
  .titleWrapper {
    display: flex;
    align-items: center;
    margin: 5px 0 30px !important;
  }

  .pageTitle {
    margin: 0 10px 0 0 !important;
    font-size: 40px !important;
    line-height: 40px !important;
  }

  .widgetsListTitle {
    margin: 0 auto 0 0 !important;
    font-weight: 500 !important;
  }

  .widgetsTitleWrapper {
    padding: 30px 0 15px;
    margin: 0 0 15px !important;
  }

  .stickyForOrder {
    background-color: #ffffff;
    position: sticky;
    top: 0;
    z-index: 100;
  }
}

.btnOrder {
  padding-left: 1rem;
  padding-right: 1rem;

  &:active {
    color: #ffffff;
  }

  &.danger {
    border-color: #990f3d;
    color: #990f3d;
  }

  &.danger:active {
    background-color: #990f3d;
  }

  &.primary {
    border-color: #00757f;
    color: #00757f;
  }

  &.primary:active {
    background-color: #00757f;
  }
}

.btnContent {
  display: flex;
  align-items: center;

  path {
    fill: currentcolor;
    transition-duration: 0.05s;
  }

  .btnLabel {
    margin-left: 0.5rem;
  }
}

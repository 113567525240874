.createMessageForm {
  form {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  form > div:nth-child(-n + 6) {
    flex-basis: 100%;
  }

  form > div:nth-child(n + 7):nth-child(-n + 10) {
    width: 48%;
  }

  .form-select-wrapper .o-forms-field {
    width: 100%;
  }
}

.historyFiltersFlexContainer {
  display: flex;
}

.dateFiltersContainer {
  margin-left: 20px;
}

.errorModalButton {
  float: right;
}

.overlay {
  width: 100% !important;
  height: 100% !important;
}

.loader {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.pagerWrapper {
  margin: 30px 0 10px;
  justify-content: center;
}

.pagerButton {
  margin: 2px !important;
}

.separator {
  display: inline-block;
  min-width: 24px;
  color: #0d7680;

  &.big {
    min-width: 36px;
  }
}

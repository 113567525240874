// Headings
.mba-heading--wrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;

  @media screen and (min-width: 475px) {
    flex-direction: row;
  }
}

.mba-heading--table {
  margin: 20px 0 10px !important;
}

.mba-heading--back {
  max-width: 100% !important;
  line-height: 32px;
  margin: 30px 0 10px !important;
  color: #262a33;
  font-size: 32px;
  font-weight: 600;
}

.mba-heading--title {
  margin: 0 !important;
  font-size: 32px !important;

  @media screen and (min-width: 740px) {
    font-size: 40px !important;
  }
}

.mba-heading--title-2 {
  margin: 0 !important;
  font-size: 26px !important;

  @media screen and (min-width: 740px) {
    font-size: 30px !important;
  }
}

.mba-back-arrow {
  display: inline-block;
  vertical-align: middle;
  margin-right: 5px;
  color: #000000;
  text-decoration: none;
  cursor: pointer;
}

// Grid columns
.mba-column-group {
  margin-top: 30px;

  &--compact {
    margin: 30px -20px 0;
  }
}

.mba-details-column {
  @media screen and (min-width: 740px) {
    padding: 0 50px !important;
    border-left: 1px solid #cccccc;

    &--single {
      padding: 0 !important;
      border-left: 0;
    }

    &--first {
      padding-right: 50px;
      padding-left: 0 !important;
      border-left: 0;
    }

    &--last {
      padding-right: 0 !important;
    }
  }
}

// Action buttons
.mba-actions {
  font-size: 0;

  &--top-right {
    position: absolute;
    top: 0;
    right: 0;
  }

  &--right {
    text-align: right;
  }

  > div:not(:last-child) {
    margin-right: 10px;
  }
}

// Tables
.mba-table-wrapper {
  margin: 20px 0 16px;

  @media screen and (min-width: 740px) {
    display: flex;
    min-height: 550px;

    .mba-table-container {
      width: 80%;
    }

    .mba-table-filters {
      z-index: 1;
      width: 20%;
      margin-right: 20px;
      padding-right: 20px;
      border-right: 1px solid #dedfe0;
    }
  }
}

// Separators
.mba-separator {
  margin: 45px 0 20px;
  border: 0;
  border-bottom: 1px solid #cccccc;
}

.mba-heading--actions {
  position: absolute;
  top: 34px;
  right: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-end;

  > *:not(:first-child) {
    margin-top: 10px;
  }
}

.mba-heading--calendar {
  margin: 2rem 0 2rem 307px !important;
  line-height: 1 !important;
}

.mba-calendar-container {
  width: 13rem;
  margin-left: 307px;
}

.mba-calendar {
  margin-bottom: 2.5rem;
}

.mba-calendar-name {
  margin-bottom: 1rem;
  display: block;
  font-size: 1.1rem;
  font-weight: 600;
  color: #00757f;
}

.mba-calendar-content {
  position: relative;
  display: flex;

  span {
    font-size: 1rem;
    white-space: nowrap;
  }
}

.mba-calendar-list {
  display: flex;
  flex-direction: column;

  &.right {
    position: absolute;
    right: 0;
    transform: translateX(100%);
  }

  .label {
    font-weight: 600;
  }
}

.calendar-form {
  .form-group {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  .form-group > div:first-child {
    flex-basis: 100%;
  }

  .form-picker-wrapper {
    flex-basis: 47%;
  }

  .form-select-wrapper {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &:not(.add) .form-select-wrapper .o-forms-field {
    width: 85%;
  }

  .form-select-wrapper .form-group-remove-btn {
    width: 24px;
    height: 24px;
    margin: 41px auto auto;
    padding: 0;
    background: none;
    border: 0;
    border-radius: 50%;
    cursor: pointer;
    outline: none !important;
  }

  .form-select-wrapper .form-group-remove-btn:hover {
    background-color: rgb(0 0 0 / 30%);
  }

  .form-select-wrapper .form-group-remove-btn:active {
    outline: none;
  }

  .react-datepicker-popper {
    width: 100%;
  }

  .react-datepicker-popper .react-datepicker {
    width: 100%;
  }

  .react-datepicker-popper .react-datepicker__month-container {
    width: 100%;
  }

  .react-datepicker-popper .react-datepicker__month {
    width: calc(100% - 0.8rem);
    display: flex;
    flex-direction: column;
  }

  .react-datepicker-popper .react-datepicker__month-wrapper {
    display: flex;
  }

  &.add form {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  &.add form > div:first-child,
  &.add form > div:last-child {
    flex-basis: 100%;
  }

  &.add .form-select-wrapper .o-forms-field {
    width: 100%;
  }
}

.mba-download-spreadsheet {
  button {
    border: 0;
    border-radius: 15px;
  }
}

.o-table td {
  vertical-align: middle !important;
}

.o-loading-overlay {
  background-color: #cccccc6b;
  width: 100%;
  height: 100%;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10;
}

.o-table .o-table__cell--numeric {
  text-align: left !important;
}

.sideBar {
  width: 260px;
  display: inline-block;
  overflow-y: hidden !important;
}

.mainContent {
  width: calc(100% - 260px);
  display: inline-block;
  vertical-align: top;
}

.subTitle {
  margin: 16px 0 0 !important;
  font-size: 1.5rem !important;
}

.sortingButton {
  outline: none;

  &:focus {
    background-color: #f4f4f5;
  }
}

.status {
  width: 80px;
}

.selection {
  max-width: 40px !important;
  width: 40px !important;
  min-width: 40px !important;
  padding: 10px !important;

  label,
  div {
    margin: 0 !important;
  }
}

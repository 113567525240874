.emailPhoneField {
  margin: 12px 0 0;
  max-width: 100%;
  flex: 1 0 auto;
  outline: 0;
  -webkit-tap-highlight-color: rgb(255 255 255 / 0%);
  text-align: left;
  line-height: 1.21428571em;
  padding: 0.4em 0.5em;
  background: #ffffff;
  border: 1px solid #808080;
  color: rgb(0 0 0 / 87%);
  transition: box-shadow 0.1s ease, border-color 0.1s ease;
  font-size: 13px;
  position: relative;

  &:focus-within {
    outline: 2px auto #101010;
    border-radius: 3px;
  }

  &.inputInvalid {
    color: #cc0000;
    border-color: #cc0000;
  }

  .emailPhonePlaceholder {
    display: none;
    position: absolute;
    left: 0.5em;
    top: 0.4em;
    padding: 0.4em;
    line-height: 1.21428571em;
  }

  .emailPhoneTag {
    display: inline-block;
    line-height: 1;
    vertical-align: baseline;
    margin: 0 0.14285714em;
    background-color: #f3f3f3;
    background-image: none;
    padding: 0.5833em 0.833em;
    color: rgb(0 0 0 / 60%);
    text-transform: none;
    font-weight: 600;
    border: 0 solid transparent;
    border-radius: 0.28571429rem;
    transition: background 0.1s ease;
    font-size: 0.8rem;

    .removeTag {
      margin-left: 0.833em;
      cursor: pointer;
      border-width: inherit;
      background-color: transparent;
      padding: 0;
    }
  }

  input {
    width: 100% !important;
    outline: none !important;
    border: 0 none !important;
    display: inline-block !important;
    line-height: 1;
    vertical-align: baseline !important;
    padding: 0.4em 0.1em !important;
  }
}

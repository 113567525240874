.modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 400px;
  max-width: 100%;
}

.modalCloseButton {
  transition: background-color 0.3s, color 0.3s;
}

.modalBackdrop {
  outline: none;
}

.modal,
.modalBackdrop {
  opacity: 0 !important;
  pointer-events: none;

  &.show {
    opacity: 1 !important;
    pointer-events: all;
    z-index: 9999;
  }
}

.modalContent {
  max-height: 80vh !important;
  overflow: auto !important;
}

.dropdownWrapper {
  position: relative;
}

.dropdown {
  padding-right: 25px;
  text-align: left;
  cursor: pointer;
}

.arrowIcon {
  position: absolute;
  top: 50%;
  right: 5px;
  width: 15px;
  height: 15px;
  pointer-events: none;
  transform: translateY(-50%);
}

.dropdownContent {
  position: absolute;
  top: 72px;
  z-index: 10;
  display: none;
  width: 100%;
  min-height: 44px;
  max-height: 35vh;
  overflow: auto;
  margin: 0 !important;
  padding: 10px;
  box-sizing: border-box;
  background-color: #ffffff;
  border: 1px solid #808080;

  &.open {
    display: block;
  }
}

.dropdownItem {
  &:last-child {
    margin-bottom: 0 !important;
  }
}

.checkboxLabelWrapper {
  display: inline-block !important;
  line-height: 24px;
}

.checkboxInput {
  margin: 0;
}

.checkboxLabel {
  padding-top: 0 !important;
  line-height: 24px;

  &::before {
    top: 50% !important;
    transform: translateY(-50%);
  }
}

.schoolLogoWrapper {
  padding: 0 !important;
}

.buttonsWrapper {
  width: 200px;
  text-align: center;

  button {
    margin: 5px auto;
  }
}

.logoWrapper {
  width: 200px;
  background: #c0c0c0;
  align-items: center;
  display: flex;
  position: relative;
  height: 200px;
  overflow: hidden;

  img {
    width: 100%;
    height: auto;
  }
}

.wrapper {
  .inner {
    padding: 0 50px;
    border-left: 1px solid #cccccc;
  }

  .inner:nth-child(2n + 1) {
    padding-left: 0 !important;
    border-left: 0;
  }
}

@media screen and (max-width: 740px) {
  .inner {
    border-left: 0 !important;
    padding: 0 !important;
  }
}
